import { graphql } from 'gatsby'
import { FunctionComponent } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { mixins, colours } from 'styles'
import { LocalFileImage } from 'types'
import { getComponentFromRT, RichTextType } from 'helpers'
import { SubscribeForm } from 'components/form/Subscribe'

const GridWrapper = styled.div`
  background-color: ${colours.white};
  .about-content {
    ${mixins.innerWrapperMixin}
    ${mixins.infoGrid}
    ${mixins.textSection}
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  h3 {
    color: ${colours.greyOne};
    font-weight: bold;
    font-size: 1.5rem;
  }
`

const ImageWrapper = styled.div`
  ${mixins.innerWrapperMixin}
  padding-top: 4rem;
  padding-bottom: 4rem;
  .gatsby-image-wrapper {
    border: 2px solid ${colours.orange};
  }
`

export interface AboutPageProps {
  data: {
    page: {
      sectionOne: RichTextType
      sectionTwo: RichTextType
      pageImages: LocalFileImage[]
    }
  }
}

export const AboutPage: FunctionComponent<AboutPageProps> = ({ data }) => (
  <>
    <SubscribeForm />
    <GridWrapper>
      <div className="about-content">
        <section>{getComponentFromRT(data.page.sectionOne)}</section>
        <section>{getComponentFromRT(data.page.sectionTwo)}</section>
      </div>
    </GridWrapper>
    <ImageWrapper>
      <Img
        alt="Truth Table"
        // only one image for this page
        fluid={data.page.pageImages[0].fluid}
      />
    </ImageWrapper>
  </>
)

export default AboutPage

export const query = graphql`
  query AboutQuery {
    page: contentfulPage(name: { eq: "about" }) {
      sectionOne {
        raw
      }
      sectionTwo {
        raw
      }
      pageImages {
        fluid(maxWidth: 1170, maxHeight: 540) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
