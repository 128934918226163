import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { colours } from 'styles'
import { useGlobalContext } from 'components/store/globalContext'

const StyledButtonWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  > .btn {
    display: block;
    font-size: 1.6rem;
    margin: 0 auto;
    width: 100px;
    border-radius: 5px;
    padding: 5px;
    border: 2px solid ${colours.darkBlack};
  }
`

interface SubscribeWrapperProps {
  isOpen: boolean
}

const SubscribeWrapper = styled.div<SubscribeWrapperProps>`
  margin-top: 200px;
  width: 320px;
  margin: 0 auto 1rem;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`

interface SubscribeFormProps {
  children?: never
}

export const SubscribeForm: FunctionComponent<SubscribeFormProps> = () => {
  const [isOpen, openForm] = useState(false)
  const [{ cookiesAccepted }] = useGlobalContext()
  return (
    <>
      <StyledButtonWrapper>
        {cookiesAccepted && (
          <button type="button" className="btn" onClick={() => openForm(!isOpen)}>
            {isOpen ? 'Close' : 'Subscribe'}
          </button>
        )}
      </StyledButtonWrapper>
      <SubscribeWrapper isOpen={isOpen}>
        <iframe
          src="https://madmimi.com/signups/136992/iframe"
          scrolling="no"
          frameBorder="0"
          height="186"
          style={{ maxWidth: '320px', width: '100%' }}
        />
      </SubscribeWrapper>
    </>
  )
}
